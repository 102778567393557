@import "../_partial/_single/series";
@import "../_partial/_single/toc";
@keyframes bottomup {
  from {
    margin-bottom: 26rem;
  }

  to {
    margin-bottom: 6rem;
  }
}
.featured-image-preview-post {
  // border-radius: 10px;
  width:100%;
  overflow: hidden;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // animation-name: flipInX;
  // animation-fill-mode: both;
  
  background-repeat: no-repeat;
  background-position: center bottom;
  background-attachment: fixed;
  background-size: 100% 100%;
  overflow: hidden;
  border-radius: 8px;
  
  @media screen and (max-width: 768px) {
    height: 130px;
    background-repeat: no-repeat;
  background-position: center center;
  background-attachment:   inherit;
  background-size: 100% auto;
  }
  img {
    width: 100%;
    vertical-align: middle;
  }
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 10px 10px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px;

}
 
.single {

.content{
  font-family: "LXGWWenKai","Open Sans", sans-serif;
}
  .single-title {
    margin: 7rem 0 1rem;
    font-weight: 500;
    line-height: 140%;
    font-size: 2.3rem;
    text-align: center;
    text-transform: uppercase;
    font-family:"LXGWWenKai", Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    // color:#fff;
    font-weight: 500;
    // text-shadow: 3px 3px 0px rgba(0, 0, 0, 0.3);
    @media screen and (max-width: 1000px) {
      font-size: 1.8rem;
      margin-top:4rem;
      margin-bottom:0.5rem;
    }
  }
  
  .single-subtitle {
    margin: 0.4rem 0;
    font-size: 1.2rem;
    font-weight: normal;
    font-style: italic;
    line-height: 100%;
  }
  
  .post-meta {
    text-align: center;
    font-size: 0.875rem;
    font-weight: normal;
    color: #bababa;
    // color: #fff;
    margin-bottom: 32px;
    animation: bottomup 1s  forwards;
    @media screen and (max-width: 1000px) {
      margin-bottom: 3rem;
      animation: none;
    }
    span {
      display: inline-block;
    }

    [theme="dark"] & {
      color: $global-font-secondary-color-dark;
    }
    [theme="black"] & {
      color: $global-font-secondary-color-black;
    }

    a,
    a::before,
    a::after {
      color: #bababa;
    }
    .author {
      font-size: 0.875rem;
      // margin-left: 0.2rem;

      margin-right: 3px;
    }
    .post-publish {
      margin-right: 15px;
    }
  }

  .featured-image {
    margin: 0.5rem 0 1rem 0;

    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
      overflow: hidden;
    }

    img.lazyloaded {
      width: 100%;
    }
  }

  .content {
    font-weight: 300;
    
    font-size: 16px;
    line-height: 28px;
    font-weight: 350;
    color: #555;
    > h2 {
      font-size: 1.5rem;
      font-weight: 800;
      & code {
        font-size: 1.25rem;
      }
    }

    > h3 {
      font-size: 1.3rem;
      font-weight: 800;
      & code {
        font-size: 1.125rem;
      }
    }

    > h4 {
      font-size: 1.1rem;
      font-weight: 800;
      & code {
        font-size: 1rem;
      }
    }

    > h5 {
      font-size: 1.125rem;
      font-weight: 800;
    }

    > h6 {
      font-size: 1rem;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 450;
      margin: 1.5rem 0;
      color:#404040;
    }

    p {
      margin: 1.2rem 0;
    } 

    b,
    strong {
      font-weight: 400;

      [theme="dark"] & {
        color: #ddd;
      }
      [theme="black"] & {
        color: #ddd;
      }
    }

    @include link(false, false);

    a {
      @include overflow-wrap(break-word);

      [theme="dark"] & b,
      [theme="dark"] & strong {
        color: $single-link-color-dark;
      }
      [theme="black"] & b,
      [theme="black"] & strong {
        color: $single-link-color-black;
      }
    }

    [theme="dark"] a:hover b,
    [theme="dark"] a:hover strong {
      color: $single-link-hover-color-dark;
    }
    [theme="black"] a:hover b,
    [theme="black"] a:hover strong {
      color: $single-link-hover-color-black;
    }

    ul,
    ol {
      margin: 0.5rem 0;
      padding-left: 2.5rem;
    }

    ul {
      list-style-type: disc;
    }

    ruby {
      background: $code-background-color;

      rt {
        color: $global-font-secondary-color;
      }

      [theme="dark"] & {
        background: $code-background-color-dark;

        rt {
          color: $global-font-secondary-color-dark;
        }
      }
      [theme="black"] & {
        background: $code-background-color-black;

        rt {
          color: $global-font-secondary-color-black;
        }
      }
    }

    .table-wrapper {
      overflow-x: auto;

      &::-webkit-scrollbar {
        background-color: $table-background-color;

        [theme="dark"] & {
          background-color: $table-background-color-dark;
        }
        [theme="black"] & {
          background-color: $table-background-color-black;
        }
      }

      > table {
        width: 100%;
        max-width: 100%;
        margin: 0.625rem 0;
        border-spacing: 0;
        background: $table-background-color;
        border-collapse: collapse;

        [theme="dark"] & {
          background: $table-background-color-dark;
        }
        [theme="black"] & {
          background: $table-background-color-dark;
        }

        thead {
          background: $table-thead-color;

          [theme="dark"] & {
            background-color: $table-thead-color-dark;
          }
          [theme="black"] & {
            background-color: $table-thead-color-black;
          }
        }

        th,
        td {
          padding: 0.3rem 1rem;
          border: 1px solid darken($table-thead-color, 2%);

          [theme="dark"] & {
            border-color: darken($table-thead-color-dark, 2%);
          }
          [theme="black"] & {
            border-color: darken($table-thead-color-black, 2%);
          }
        }
      }
    }

    img {
      max-width: 100%;
      min-height: 1em;
      position: relative;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 5px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px;
      margin-bottom: 0.5rem;
      border-radius: 8px;
    }
    video {
      max-width: 100%;
      min-height: 1em;
      position: relative;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 5px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px;
      margin-bottom: 0.5rem;
      border-radius: 8px;
    }
    .bilibili {
      border-radius: 8px;
      overflow: hidden;
    }
    figure {
      margin: 0.5rem;
      text-align: center;

      .image-caption:not(:empty) {
        min-width: 20%;
        max-width: 80%;
        display: inline-block;
        padding: 0.5rem;
        margin: 0 auto;
        font-size: 0.875rem;
        color: #969696;
      }

      img {
        display: block;
        height: auto;
        margin: 0 auto;
        overflow: hidden;
      }
    }

    .lazyloading {
      @include object-fit(none);
    }

    blockquote {
      display: block;
      border-left: 0.5rem solid $blockquote-color;
      background-color: rgba($blockquote-color, 0.2);
      padding: 0.25rem 0.75rem;
      margin: 1rem 0;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
      color: #666;

      [theme="dark"] & {
        border-left-color: $blockquote-color-dark;
        background-color: rgba($blockquote-color-dark, 0.2);
      }
      [theme="black"] & {
        border-left-color: $blockquote-color-black;
        background-color: rgba($blockquote-color-black, 0.2);
      }
    }

    .footnotes {
      color: $global-font-secondary-color;

      [theme="dark"] & {
        color: $global-font-secondary-color-dark;
      }
      [theme="black"] & {
        color: $global-font-secondary-color-black;
      }

      p {
        margin: 0.25rem 0;
      }
    }

    @import "../_partial/_single/code";
    @import "../_partial/_single/katex";
    @import "../_partial/_single/admonition";
    @import "../_partial/_single/echarts";
    @import "../_partial/_single/mapbox";
    @import "../_partial/_single/music";
    @import "../_partial/_single/bilibili";
    @import "../_partial/_single/friend";
    @import "../_partial/_single/showcase";

    hr {
      margin: 2rem 0;
      position: relative;
      border-top: 3px dashed $global-border-color;
      border-bottom: none;

      [theme="dark"] & {
        border-top: 3px dashed $global-border-color-dark;
      }
      [theme="black"] & {
        border-top: 3px dashed $global-border-color-black;
      }
    }

    kbd {
      display: inline-block;
      padding: 0.25rem;
      background-color: $global-background-color;
      border: 1px solid $global-border-color;
      border-bottom-color: $global-border-color;
      @include border-radius(3px);
      @include box-shadow(inset 0 -1px 0 $global-border-color);
      font-size: 0.8rem;
      font-family: $code-font-family;
      color: $code-color;

      [theme="dark"] & {
        background-color: $global-background-color-dark;
        border: 1px solid $global-border-color-dark;
        border-bottom-color: $global-border-color-dark;
        @include box-shadow(inset 0 -1px 0 $global-border-color-dark);
        color: $code-color-dark;
      }
      [theme="black"] & {
        background-color: $global-background-color-black;
        border: 1px solid $global-border-color-black;
        border-bottom-color: $global-border-color-black;
        @include box-shadow(inset 0 -1px 0 $global-border-color-black);
        color: $code-color-black;
      }
    }

    .typeit {
      .code {
        padding: 0.375rem;
        font-size: 0.875rem;
        font-family: $code-font-family;
        font-weight: bold;
        word-break: break-all;
      }
    }

    .version {
      height: 1.25em;
      vertical-align: text-bottom;
    }
  }

  @import "../_partial/_single/footer";
  @import "../_partial/_single/comment";
  @import "../_partial/_single/sponsor";
}

.lg-toolbar .lg-icon::after {
  color: #999;
}

#TableOfContents {
  max-height: 35vh;
  overflow: auto;
}

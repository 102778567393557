.home {
  .home-profile {
    @include transform(translateY(16vh));
    padding: 0 0 0.5rem;
    text-align: center;

    .home-avatar {
      padding: 0.5rem;

      img {
        display: inline-block;
        width: 8rem;
        height: auto;
        margin: 0 auto;
        @include border-radius(100%);
        @include box-shadow(0 0 0 0.3618em rgba(0, 0, 0, 0.05));
        @include transition(all 0.4s ease);

        &:hover {
          position: relative;
          @include transform(translateY(-0.75rem));
        }
      }
    }

    .home-title {
      font-size: 1.25rem;
      font-weight: bold;
      margin: 0;
      padding: 0.5rem;
    }

    .home-subtitle {
      font-size: 1rem;
      font-weight: normal;
      margin: 0;
      padding: 0.5rem;
    }

    .links {
      padding: 0.5rem;
      font-size: 1.5rem;

      a * {
        vertical-align: text-bottom;
      }

      img {
        height: 1.5rem;
        padding: 0 0.25rem;
      }
    }

    .home-disclaimer {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: normal;
      margin: 0;
      padding: 0.5rem;
      color: $global-font-secondary-color;

      [theme="dark"] & {
        color: $global-font-secondary-color-dark;
      }
      [theme="black"] & {
        color: $global-font-secondary-color-black;
      }
    }
  }
}

.home[posts] {
  .home-profile {
    @include transform(translateY(0));
    padding-top: 2rem;
  }

  .home-avatar img {
    width: 6rem;
  }
  .summarys {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .summary {
    .post-meta{
      margin-bottom:0;
      animation: none;text-align: left;

    }
    .single-title{
      text-align: left;

      a{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        .title-content{
          display: block;
          text-overflow: ellipsis;
          height:30px;white-space:nowrap;
          overflow:hidden;
        }
      }
    }
    margin-bottom: 2rem;
    padding-bottom: 0rem;
    width: 49%;
    color: $global-font-color;
    border: 1px solid #f0f8ff;
    &::before {
      content: "";
      position: absolute;
      top: 0px;
      right: 10px;
      bottom: 0;
      left: 10px;
      z-index: -1;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 10px 10px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px;
      transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
    }
    border-radius: 12px;
    position: relative;
    background: #fff;

    [theme="dark"] & {
      color: $global-font-color-dark;
      border-bottom: 3px dashed $global-border-color-dark;
    }
    [theme="black"] & {
      color: $global-font-color-black;
      border-bottom: 3px dashed $global-border-color-black;
    }

    @media (max-width: 768px) {
      & {
        width: 100%;
      }
    }
    transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
    

    &:hover {
      // transform: translateY(-0.3rem);
    }
    &:hover::before {
      z-index: -1;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 10px 0px,
        rgba(0, 0, 0, 0) 0px 0px 0px 0px;
    }

    .featured-image-preview {
      width: 100%;
      padding: 35% 0 0;
      position: relative;
      margin: 0rem auto;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      overflow: hidden;
      @include transition(transform 0.4s ease);

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        @include object-fit(none);
      }

      img.lazyloaded {
        @include object-fit(cover);
      }
    }
    .meta {
      background: #fff;
      padding: 1rem;
    }
    .title-date {
      background-color: #9fa476;
      display: inline-block;
      padding: 0.2rem;
      margin-left: -8px;
      color: #fff;
      border-radius: 5px;
      font-family: kefa;
      transform: scale(0.55);
      width:2rem;
      height:2rem;
      vertical-align: -5px;
      margin-top:-6px;
      .year {
        font-size: 0.8rem;
        display: block;
        text-align: center;
        line-height: 1rem;
      }
      .month {
        font-size: 0.8rem;
        display: block;
        text-align: center;
        line-height: 1rem;
        text-transform: uppercase;
      }
    }
    .single-title {
      font-size: 1.2rem;
      line-height: 140%;
      margin: 0rem 0rem 0rem 0rem;
      font-weight: 350;
      .title-content {
        margin-left: -3px;
      }
    }
    .post-meta {
      margin-top: 0rem;
      margin: 0rem 0rem 0rem 0rem;
    }
    .post-footer{
      display: none;
    }
    .content {
      @include box(vertical);
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      @include overflow-wrap(break-word);
      color: $global-font-secondary-color;
      font-weight: 300;
      // color: var(--card-text-color-secondary);
      margin: 7px 0;
      line-height: 1.5;
      [theme="dark"] & {
        color: $global-font-secondary-color-dark;
      }
      [theme="black"] & {
        color: $global-font-secondary-color-black;
      }

      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        font-size: 1rem;
        line-height: 1.5;
        display: inline;

        &::after {
          content: "\A";
          white-space: pre;
        }
      }

      h2 {
        font-size: 1.125rem;
      }

      @include link(false, true);

      b,
      strong {
        color: $global-font-secondary-color;

        [theme="dark"] & {
          color: $global-font-secondary-color-dark;
        }
        [theme="black"] & {
          color: $global-font-secondary-color-black;
        }
      }
    }

    .post-footer {
      margin-top: 0.4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.875rem;

      @include link(false, false);

      .post-tags {
        padding: 0;

        @include link(true, true);
      }
    }
  }

  .summary:nth-child(4n) {
    .title-date {
      background-color: #169d98;
    }
  }
  .summary:nth-child(4n + 1) {
    .title-date {
      background-color: #d192f8;
    }
  }
  .summary:nth-child(4n + 2) {
    .title-date {
      background-color: #6bcdb8;
    }
  }

  .summary:nth-child(4n + 3) {
    .title-date {
      background-color: #ff2955;
    }
  }
  .summary:nth-child(4n + 4) {
    .title-date {
      background-color: #f2c054;
    }
  }
}
